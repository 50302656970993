import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import Login from '../views/Login.vue';
//import ClienteDetail from '../views/ClienteDetail.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const user = localStorage.getItem("userInfo");
      const ricordami = localStorage.getItem("ricordami");
      if (user && ricordami) {
        next({ path: '/tabs/' });
      } else {
        next();
      }
    }
  }, {
    path: '/presenze',
    component: () => import('@/views/ElencoPresenze.vue'),
  },

  {
    path: '/tabs/',
    component: Tabs,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1.vue'), //Presenze
      },
      {
        path: "tab1/:id",
        component: () => import('@/views/InterventoDetail.vue') //Dettaglio presenza
      },
      /*       {
              path: "tab1/:id",
              //component: ClienteDetail,
              name: 'ClienteDetail',
              component: () => import('@/views/ClienteDetail.vue')
            },
      {
        path: "tab1/:id/commessa/:id_commessa",
        name: 'CommessaDetail',
        component: () => import('@/views/CommessaDetail.vue')
      },*/
      {
        path: 'tab2',
        component: () => import('@/views/Tab2.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3.vue')
      },
      {
        path: 'tab4',
        component: () => import('@/views/Tab4.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem("userInfo");
  //const ricordami = localStorage.getItem("ricordami");
  /*console.log(ricordami);
  console.log("!ricordami: ", !ricordami)
  console.log("!user: ", !user)*/
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user == null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next();
  }
})

export default router
