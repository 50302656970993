<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Installazione</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Installazione</ion-title>
        </ion-toolbar>
      </ion-header>

      <!--  iOS instructions -->
      <ion-grid class="ion-padding-horizontal" v-show="showIos">
        <ion-row>
          <ion-col size="12">
            <ion-text>
              Cliccando sul pulsante evidenziato si aprirà una schermata in cui
              potrai installare l'applicazione selezionando "Aggiungi alla
              schermata Home"
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <img
              src="assets/pwa_instructions/step1_installazione.png"
              class="img_step1"
            />
          </ion-col>
          <ion-col size="12">
            <div class="img_container">
              <img
                src="assets/pwa_instructions/step2_home.png"
                class="img_step2"
              />
            </div>
          </ion-col>
          <ion-col size="12">
            <ion-button color="danger" expand="block" @click="closeModal">
              <span class="btn_close">Chiudi</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!--  Android instructions -->
      <ion-grid class="ion-padding-horizontal" v-show="showAndroid">
        <ion-row>
          <ion-col size="12">
            <ion-text>
              Cliccando sul banner che compare a fondo pagina oppure andando nel
              menù e cliccando su "Installa app" si aprirà una schermata in cui
              potrai installare l'applicazione selezionando "Installa". <br />
              La procedura utilizza Google Chrome come browser di riferimento.
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <img
              src="assets/pwa_instructions/android/step1_only_banner.png"
              class="img_step1"
            />
          </ion-col>
          <ion-col size="12">
            <div class="img_container">
              <img
                src="assets/pwa_instructions/android/step1_menu.png"
                class="img_step2"
              />
            </div>
          </ion-col>
          <ion-col size="12">
            <div class="img_container">
              <img
                src="assets/pwa_instructions/android/step2_popup.png"
                class="img_step2"
              />
            </div>
          </ion-col>
          <ion-col size="12">
            <ion-button color="danger" expand="block" @click="closeModal">
              <span class="btn_close">Chiudi</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  //IonImg,
  getPlatforms,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PwaInstructions",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    //IonImg,
  },
  setup(props, context) {
    const showIos = ref(false);
    const showAndroid = ref(false);

    //alert(getPlatforms());

    const platforms = getPlatforms();

    if (platforms.includes("android")) {
      //alert("Pwa running on Android");
      showAndroid.value = true;
    } else if (platforms.includes("ios") || platforms.includes("iphone")) {
      //alert("Pwa running on iOS or iphone");
      showIos.value = true;
    }

    /**
     *
     * Handle close modal by clicking on Chiudi button
     *
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    return {
      showIos,
      showAndroid,
      closeModal,
    };
  },
});
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img_photo {
  position: relative;
}
.img_action {
  position: absolute;
  top: -5px;
  right: -15px;
}

.select100 {
  max-width: 100% !important;
}

.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.img_container {
  display: flex;
  justify-content: center;
}

.img_step1 {
  margin-bottom: 10px;
}

.img_step2 {
  height: 400px;
  max-width: 100%;
}

.btn_close_container {
  display: flex;
  justify-content: center;
}
.btn_close {
  padding: 60px;
}
</style>
