
import { IonApp, IonRouterOutlet, IonButton } from "@ionic/vue";
import { defineComponent, ref } from "vue";

//import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonButton,
  },
  /*setup() {
    const router = useRouter();
    console.log("inside App.vue");
    if (
      localStorage.getItem("userInfo") != null &&
      localStorage.getItem("ricordami") != null
    ) {
      console.log(localStorage.getItem("ricordami"));
      router.replace("/tabs/");
    }
  },*/
  setup() {
    const deferredPrompt = ref(null);

    /* Receive event before install pwa to prompt user */
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt.value = e;
    });

    window.addEventListener("appinstalled", () => {
      deferredPrompt.value = null;
    });

    async function dismiss() {
      deferredPrompt.value = null;
    }
    async function install() {
      deferredPrompt.value.prompt();
    }

    return {
      deferredPrompt,
      dismiss,
      install,
    };
  },
});
