<template>
  <ion-app>
    <div v-if="deferredPrompt" class="app_install_container">
      <div class="banner_content">
        <div class="banner">
          <img src="assets/almarei_logo.png" />
          <h4 class="banner_text">Installa applicazione</h4>
        </div>
        <div class="btn_container_pwa">
          <ion-button class="btn_install" @click="install"> Ok </ion-button>
          <ion-button class="btn_dismiss" @click="dismiss">chiudi</ion-button>
        </div>
      </div>
    </div>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet, IonButton } from "@ionic/vue";
import { defineComponent, ref } from "vue";

//import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonButton,
  },
  /*setup() {
    const router = useRouter();
    console.log("inside App.vue");
    if (
      localStorage.getItem("userInfo") != null &&
      localStorage.getItem("ricordami") != null
    ) {
      console.log(localStorage.getItem("ricordami"));
      router.replace("/tabs/");
    }
  },*/
  setup() {
    const deferredPrompt = ref(null);

    /* Receive event before install pwa to prompt user */
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt.value = e;
    });

    window.addEventListener("appinstalled", () => {
      deferredPrompt.value = null;
    });

    async function dismiss() {
      deferredPrompt.value = null;
    }
    async function install() {
      deferredPrompt.value.prompt();
    }

    return {
      deferredPrompt,
      dismiss,
      install,
    };
  },
});
</script>

<style scoped>
.app_install_container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: rgb(255, 255, 255);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}
.banner_content {
  max-width: 400px;
  font-size: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  color: #000000;
  width: 100%;
}
.banner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.banner img {
  width: 100px;
}
.banner_text {
  margin-top: 0px;
}

.btn_container_pwa {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn_install {
  --background: #0087ca;
  --background-activated: #0378b3;
  --background-hover: #0378b3;
  --background-focused: #0378b3;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
}
.btn_dismiss {
  /* --background: #1b1b1b;
  --background-activated: #1b1b1b;
  --background-hover: #1b1b1b;
  --background-focused: #1b1b1b;*/
  --background: transparent;
  --background-activated: transparent;
  --background-hover: transparent;
  --background-focused: transparent;
  --box-shadow: none;
  --border-color: #1b1b1b;
  --border-width: 2px;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  width: 100%;
  border: 1px solid #1b1b1b;
  border-radius: 4px;
  color: #1b1b1b;
}
</style>