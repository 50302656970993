<template>
  <ion-page>
    <!--<ion-header>
      <ion-toolbar>
        <ion-title>Login page</ion-title>
      </ion-toolbar>
    </ion-header>-->
    <ion-content :fullscreen="true">
      <!--<div v-if="loading" class="full_container">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>-->
      <div class="full_container">
        <ion-grid>
          <ion-row>
            <ion-col class="ion-padding">
              <div class="logo_container">
                <img src="assets/almarei_logo.png" style="max-height: 200px" />
              </div>
            </ion-col>
          </ion-row>
          <form @submit.prevent="doLogin()">
            <ion-row>
              <ion-col class="ion-padding">
                <ion-item>
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input type="email" required v-model="email"></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="stacked">Password</ion-label>
                  <ion-input
                    type="password"
                    required
                    v-model="password"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-checkbox class="ion-margin-end" v-model="ricordami">
                  </ion-checkbox>
                  <ion-label>Ricordami</ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-padding">
                <ion-button
                  color="primary"
                  size="default"
                  type="submit"
                  expand="full"
                >
                  Login
                </ion-button>
                <ion-button
                  color="secondary"
                  fill="clear"
                  size="small"
                  expand="full"
                  @click="openModal"
                  class="btn_helper"
                >
                  Come installare l'applicazione
                </ion-button>
              </ion-col>
            </ion-row>
          </form>
        </ion-grid>
      </div>
      <ion-loading
        :is-open="loading"
        message="Autenticazione in corso"
        :duration="5000"
        @didDismiss="setOpen(false)"
      >
      </ion-loading>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCheckbox,
  //IonSpinner,
  IonLoading,
  modalController,
  //toastController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";
import md5 from "md5";
import { useRouter } from "vue-router";

import { openToast } from "../services/toast";
//Set user in indexedDB --> dbUser --> user
import { setUser } from "../services/localbase";

import PwaInstructionsVue from "../components/PwaInstructions.vue";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonCheckbox,
    //IonSpinner,
    IonLoading,
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const isSignedIn = ref(false);
    //const userData: string[] = reactive([]);
    const ricordami = ref(false);
    const loading = ref(false);
    //loading spinner
    const setOpen = (state: boolean) => (loading.value = state);

    const router = useRouter();

    async function openModal() {
      const modal = await modalController.create({
        component: PwaInstructionsVue,
      });
      return modal.present();
    }

    //Login function
    function doLogin() {
      //this.loader = true;
      loading.value = true;
      const hashPassword = md5(password.value.toString());

      const data = new FormData();
      data.append("users_email", email.value);
      data.append("users_password", hashPassword.toString());

      axios
        .post("https://crm.almarei.it/rest/v1/login/users", data, {
          headers: {
            Authorization: "Bearer 26d460b39301781b10d37af31e2e5a4a",
          },
        })
        .then(function (response) {
          // handle success
          if (response.data.status == 0 && response.data.data.length != 0) {
            //userData.push(response.data.data);
            //save user data to localStorage and redirect to homepage
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.data)
            );
            //Save user data in indexeddb
            setUser(response.data.data);
            if (ricordami.value) {
              localStorage.setItem("ricordami", `${ricordami.value}`);
            }
            email.value = "";
            password.value = "";
            ricordami.value = false;
            loading.value = false;
            router.push("/tabs/");
          } else {
            openToast("Login fallito, controlla email e password", "danger");
          }
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    return {
      email,
      password,
      doLogin,
      ricordami,
      loading,
      //spinner login
      //isOpenRef,
      setOpen,
      openModal,
    };
  },
});
</script>

<style scoped>
.full_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn_helper {
  margin-top: 30px;
}
</style>